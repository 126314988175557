<template>
  <div class="icon">
    <i class="el-icon-loading"></i>
    <p>正在进入，请稍等。。</p>
  </div>
</template>
<script>
import { _decodeServer } from "~/basePlugins/base64";
import { handleToVideoChatPage } from "~/baseUtils";

export default {
  data() {
    return {};
  },
  mounted() {
    let token = this.$route.query.access_token;
    this.chatVal = this._decode(this.$route.query.chatVal);
    this.code = this.$route.query.code;
    if (this.code) {
      this.getUser();
    } else {
      this.getUserTokenInfo(token);
    }
  },
  methods: {
    async getUser() {
      let params = {
        identity_type: 4,
        identity_str: this.code,
      };
      params.source = this.PJSource;
      try {
        let res = await this.$store.dispatch("register/wxLogin", params);
        if (res.success) {
          if (res.data.is_new == 0) {
            handleToVideoChatPage(
              this.chatVal.channel_id,
              res.data.user_id,
              "miniBooth",
              true,
              this.chatVal.back_url
            );
          } else {
            this.$router.push({
              path: "/skipBing",
              query: {
                unionid: res.data.unionid,
              },
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getUserTokenInfo(token) {
      if (!token) return;
      try {
        let user_result = await this.$store.dispatch(
          "baseStore/baseSign_getUserTokenInfo",
          {
            access_token: token,
          }
        );
        if (user_result.success) {
          console.log(user_result);
          handleToVideoChatPage(
            this.chatVal.channel_id,
            user_result.data.id,
            "miniBooth",
            true,
            this.chatVal.back_url
          );
        }
      } catch (e) {}
    },
  },
};
</script>
<style lang="less" scoped>
.icon {
  padding: 300px 0;
  text-align: center;
  i {
    font-size: 30px;
  }
  p {
    font-size: 16px;
    margin-top: 20px;
  }
}
</style>